import React from 'react';

import cx from 'classnames';

export type StackProps = {
    children?: React.ReactNode | React.ReactNode[];
    /**
     * If stack wrapper takes 100% of parent's width
     */
    fullWidth?: boolean;
    /**
     * Direction of stacking
     */
    direction?: 'column' | 'row';
    /**
     * Align of stacking
     */
    align?: 'start' | 'center' | 'end' | 'stretch';
    /**
     * Distance between stack children
     */
    spacing?: 'default';
    key?: string;
    extraClass?: string;
};

function Stack({
    children,
    fullWidth = true,
    direction = 'column',
    align = 'stretch',
    key,
    spacing = 'default',
    extraClass,
}: StackProps) {
    const className = cx({
        stack: true,
        [`stack--fullwidth`]: fullWidth,
        [`stack--${direction}`]: direction,
        [`stack--align-${align}`]: align,
        [`stack--spacing-${spacing}`]: spacing,
        [extraClass as string]: extraClass,
    });
    return (
        <div className={className}>
            {React.Children.toArray(children)
                .filter(Boolean)
                .map((item, index) => (
                    <div className="stack__item" key={`stack-${key}-${index}`}>
                        {item}
                    </div>
                ))}
        </div>
    );
}

export default Stack;
