import React, { useEffect, useRef } from 'react';

import { footer } from 'components/lexemes';
import { socialLinks } from 'lib/social-links';

import { onAppleStoreClick, onGoogleStoreClick } from 'containers/events';

import AppLinks from 'components/app-links/app-links';
import Icon from 'components/icon/icon';
import SocialLinks from 'components/social-links/social-links';

import HeaderNavigationSlideoverCta from './header-navigation-slideover-cta';
type SlideoverProps = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

function HeaderMobileMenuSlideover(props: SlideoverProps) {
    const modalRef = useRef(null);

    // Handle close on Escape key
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                props.onClose();
            }
        };
        if (props.isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props, props.isOpen, props.onClose]);

    return (
        <div
            className={`${'slideover'} ${props.isOpen ? 'display-block' : 'display-none'}`}
            ref={modalRef}
            role="dialog"
            aria-labelledby="slideover-title"
        >
            <div className="slideover__container">
                <div className="slideover__body">
                    <div className="slideover__button-container">
                        <button
                            type="button"
                            className="slideover__button"
                            onClick={props.onClose}
                            aria-label="Close"
                        >
                            <div style={{ width: 16, height: 16 }}>
                                <Icon name="generic-chevron-back" />
                            </div>
                            <span className="slideover__button-text">Back</span>
                        </button>
                    </div>
                    <div className="slideover__navigation-list">{props.children}</div>

                    <HeaderNavigationSlideoverCta feature="travel-mode" />

                    <div className="header-mobile-menu__social-links">
                        <SocialLinks items={socialLinks} ariaLabel={footer.lxFollowUsTitle()} />
                    </div>

                    <div className="slideover__footer">
                        <AppLinks
                            onAppleStoreClick={onAppleStoreClick}
                            onGoogleStoreClick={onGoogleStoreClick}
                            description={footer.lxDownloadCopyText()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HeaderMobileMenuSlideover;
