import React, { useContext } from 'react';

import cx from 'classnames';
import LocaleContext from 'lib/locale-context';

import Link from 'components/link/link';

type NavigationModalListProps = {
    linkList: {
        getUrl(lang: string): string;
        onClick?: () => void;
        title?: string | React.ReactNode;
        descriptionText?: string | React.ReactNode;
        isActive?: boolean;
    }[];
};

function NavigationModalList(props: NavigationModalListProps) {
    const lang = useContext(LocaleContext);
    return (
        <div className={`${props.linkList.length > 3 && 'modal-list__grid'}`}>
            {props.linkList.map((menuItem, dropdownIndex) => {
                const classNameSubLink = cx({
                    'modal-list__dropdown-link': true,
                    'is-active': menuItem.isActive,
                });

                return (
                    <div
                        className="modal-list__item"
                        key={`header-menu--dropdown-item-${dropdownIndex}`}
                    >
                        <Link
                            className={classNameSubLink}
                            href={menuItem.getUrl(lang)}
                            onClick={menuItem.onClick}
                        >
                            {menuItem.title}
                        </Link>
                        {menuItem.descriptionText && (
                            <p className="modal-list__description">{menuItem.descriptionText}</p>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default NavigationModalList;
