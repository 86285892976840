import React, { useState } from 'react';

import cx from 'classnames';
import footer from 'components/lexemes/footer';
import FocusTrap from 'focus-trap-react';
import { socialLinks } from 'lib/social-links';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import AppLinks from 'components/app-links/app-links';
import Link from 'components/link/link';
import SocialLinks from 'components/social-links/social-links';

import HeaderMobileMenuSlideoverList from './header-mobile-menu-mobile-slideover-list';
import HeaderMobileMenuSlideover from './header-mobile-menu-slideover';

type Props = {
    items?: {
        isActive?: boolean;
        appHeaderModalMenu?: {
            getUrl(lang: string): string;
            title?: string | React.ReactNode;
            onClick?: () => void;
        }[];
        featureHeaderModalMenu?: {
            getUrl(lang: string): string;
            title?: string | React.ReactNode;
            onClick?: () => void;
        }[];
        url?: string;
        title?: string | React.ReactNode;
        onClick?: () => void;
        extraClass?: string;
    }[];
    id?: string;
    onAppleStoreClick?: () => void;
    onGoogleStoreClick?: () => void;
    ariaLabel: string;
};

type SlideoverId = 'appSlideover' | 'featureSlideover' | null;

function HeaderMobileMenu({ items, id, onAppleStoreClick, onGoogleStoreClick, ariaLabel }: Props) {
    const [showMenu, setMenuVisible] = useState(false);

    const [activeSlideover, setActiveSlideover] = useState<SlideoverId>(null);
    const openSlideover = (slideoverId: SlideoverId) => setActiveSlideover(slideoverId);
    const closeSlideover = () => setActiveSlideover(null);

    return (
        <nav className="header-mobile-menu" id={id} aria-label={ariaLabel}>
            <div className="header-mobile-menu__inner">
                <ul className="header-mobile-menu__list" role="list">
                    {items &&
                        items.map((item, index) => {
                            const {
                                isActive,
                                appHeaderModalMenu,
                                featureHeaderModalMenu,
                                url,
                                title,
                                extraClass,
                            } = item;
                            const className = cx({
                                'header-mobile-menu__item': true,
                                'is-active': isActive,
                            });

                            return (
                                <li className={className} key={`header-mobile-menu-item-${index}`}>
                                    {url ? (
                                        <Link
                                            className={
                                                extraClass
                                                    ? `${extraClass} header-mobile-menu__link`
                                                    : 'header-mobile-menu__link'
                                            }
                                            //extraClass
                                            href={url}
                                            onClick={item.onClick}
                                            aria-current={isActive ? 'page' : undefined}
                                        >
                                            {title}
                                        </Link>
                                    ) : null}

                                    {appHeaderModalMenu ? (
                                        <FocusTrap
                                            focusTrapOptions={{
                                                escapeDeactivates: true,
                                                onDeactivate: () => setMenuVisible(false),
                                                initialFocus: false,
                                                allowOutsideClick: true,
                                            }}
                                            active={showMenu}
                                        >
                                            <div className="header-mobile-menu__link-container">
                                                <button
                                                    className={
                                                        extraClass
                                                            ? `${extraClass} header-mobile-menu__link`
                                                            : 'header-mobile-menu__link'
                                                    }
                                                    aria-expanded={Boolean(showMenu)}
                                                    onClick={() => openSlideover('appSlideover')}
                                                    type="button"
                                                >
                                                    {url ? (
                                                        <A11yVisuallyHidden>
                                                            {showMenu
                                                                ? 'Hide submenu'
                                                                : 'Show submenu'}
                                                        </A11yVisuallyHidden>
                                                    ) : (
                                                        title
                                                    )}
                                                </button>
                                                <HeaderMobileMenuSlideover
                                                    isOpen={activeSlideover === 'appSlideover'}
                                                    onClose={closeSlideover}
                                                >
                                                    <HeaderMobileMenuSlideoverList
                                                        linkList={appHeaderModalMenu}
                                                    />
                                                </HeaderMobileMenuSlideover>
                                            </div>
                                        </FocusTrap>
                                    ) : null}

                                    {featureHeaderModalMenu ? (
                                        <FocusTrap
                                            focusTrapOptions={{
                                                escapeDeactivates: true,
                                                onDeactivate: () => setMenuVisible(false),
                                                initialFocus: false,
                                                allowOutsideClick: true,
                                            }}
                                            active={showMenu}
                                        >
                                            <div className="header-mobile-menu__link-container">
                                                <button
                                                    className={
                                                        extraClass
                                                            ? `${extraClass} header-mobile-menu__link`
                                                            : 'header-mobile-menu__link'
                                                    }
                                                    aria-expanded={Boolean(showMenu)}
                                                    onClick={() =>
                                                        openSlideover('featureSlideover')
                                                    }
                                                    type="button"
                                                >
                                                    {url ? (
                                                        <A11yVisuallyHidden>
                                                            {showMenu
                                                                ? 'Hide submenu'
                                                                : 'Show submenu'}
                                                        </A11yVisuallyHidden>
                                                    ) : (
                                                        title
                                                    )}
                                                </button>
                                                <HeaderMobileMenuSlideover
                                                    isOpen={activeSlideover === 'featureSlideover'}
                                                    onClose={closeSlideover}
                                                >
                                                    <HeaderMobileMenuSlideoverList
                                                        linkList={featureHeaderModalMenu}
                                                    />
                                                </HeaderMobileMenuSlideover>
                                            </div>
                                        </FocusTrap>
                                    ) : null}
                                </li>
                            );
                        })}
                </ul>

                {!activeSlideover && (
                    <div className="header-mobile-menu__footer-container">
                        <div className="header-mobile-menu__social-links">
                            <SocialLinks items={socialLinks} ariaLabel={footer.lxFollowUsTitle()} />
                        </div>

                        <div className="header-mobile-menu__footer">
                            <AppLinks
                                onAppleStoreClick={onAppleStoreClick}
                                onGoogleStoreClick={onGoogleStoreClick}
                                description={footer.lxDownloadCopyText()}
                            />
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
}

export default HeaderMobileMenu;
