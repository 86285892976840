import React, { useEffect } from 'react';

import Image, { ImageProps } from 'next/image';

function setFeatureImageLangFallback(src: string) {
    return src.toString().startsWith('/bumble-brand-assets/bumble/l-assets/')
        ? src
              .toString()
              .replace(
                  /\/bumble-brand-assets\/bumble\/l-assets\/(.+)\/features/g,
                  '/bumble-brand-assets/bumble/l-assets/en/features',
              )
        : null;
}

const ImageContainer = (props: ImageProps) => {
    const [imgSrc, setImgSrc] = React.useState(props.src);

    useEffect(() => {
        setImgSrc(props.src);
    }, [props.src]);

    /**
     * fallback for feature images
     */
    const fallbackSrc =
        typeof props.src === 'string' ? setFeatureImageLangFallback(props.src) : null;

    return (
        <Image
            {...props}
            width={props.width}
            height={props.height}
            fill={!props.width && !props.height}
            src={imgSrc}
            onError={() => {
                if (fallbackSrc) {
                    setImgSrc(fallbackSrc);
                }
            }}
            // unoptimized={true}
            // unoptimized={src.endsWith('svg')}
        />
    );
};

export default ImageContainer;
