import React, { useEffect, useRef } from 'react';

import Icon from 'components/icon/icon';

import NavigationModalCta from './navigation-modal-cta';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
};

function NavigationModal(props: ModalProps) {
    const modalRef = useRef<HTMLDivElement>(null);

    // Handle closing modal on Escape key
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                props.onClose();
            }
        };
        if (props.isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props, props.isOpen, props.onClose]);

    // Close modal if clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as HTMLDivElement)) {
                props.onClose();
            }
        };
        if (props.isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [props, props.isOpen, props.onClose]);
    if (!props.isOpen) return null;

    return (
        <div
            className={`${'modal'} ${props.isOpen ? 'display-block' : 'display-none'}`}
            ref={modalRef}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
        >
            <div className="modal__container">
                <div className="modal__body">
                    <div className="modal__button-container">
                        <button
                            type="button"
                            className="modal__button"
                            onClick={props.onClose}
                            aria-label="Close"
                        >
                            <div style={{ width: 20, height: 20 }}>
                                <Icon name="icon-close" />
                            </div>
                        </button>
                    </div>
                    <div className="modal__navigation-list">{props.children}</div>

                    <NavigationModalCta feature="travel-mode" />
                </div>
            </div>
        </div>
    );
}

export default NavigationModal;
