import React, { FunctionComponent } from 'react';

import { header, common, footer } from 'components/lexemes';
import { useGlobalProps } from 'lib/hooks/use-global-props';
import { signInAction } from 'lib/seo/seo';

import {
    onAppleStoreClick,
    onGoogleStoreClick,
    onMenuItemClickHandler,
    onClickHeaderOpenMenuCta,
} from 'containers/events';

import Header from 'components/header/header';
import HeaderMenu, { HeaderMenuProps } from 'components/header/header-menu';
import HeaderMobileMenu from 'components/header/header-mobile-menu';
import Logo from 'components/logo/logo';
import SkipLink from 'components/skip-link/skip-link';

// TODO: better detection of active page
function setActivePages(pages: HeaderMenuProps['items'] = [], currentUrl: string) {
    pages.forEach((page) => {
        // Check if the current URL starts with the page URL
        if (page.url && currentUrl.startsWith(page.url)) {
            // Ensure that the match is exact or followed by a '/'
            const nextChar = currentUrl[page.url.length];
            if (!nextChar || nextChar === '/') {
                page.isActive = true;
            } else {
                page.isActive = false;
            }
        } else {
            page.isActive = false;
        }

        // Recursively check appHeaderModalMenu if it exists
        if (page.appHeaderModalMenu && page.appHeaderModalMenu.length > 0) {
            setActivePages(page.appHeaderModalMenu, currentUrl);
            // If any dropdown item is active, mark the parent as active too
            page.isActive = page.appHeaderModalMenu.some((subPage) => subPage.isActive);
        }

        // Recursively check featureHeaderModalMenu if it exists
        if (page.featureHeaderModalMenu && page.featureHeaderModalMenu.length > 0) {
            setActivePages(page.featureHeaderModalMenu, currentUrl);
            // If any dropdown item is active, mark the parent as active too
            page.isActive = page.featureHeaderModalMenu.some((subPage) => subPage.isActive);
        }
    });
}

export const HeaderContainer: FunctionComponent = () => {
    const globalProps = useGlobalProps();
    const lang = globalProps?.lang;

    const signinCta = {
        title: footer.lxFooterItemLabelSignIn(),
        url: `${signInAction}&utm_content=header`,
        onClick: () => onMenuItemClickHandler('sign-in'),
        extraClass: 'button--cta-ab-test',
        'data-seo-label': 'sign-in',
    };

    const headerMenuItems: HeaderMenuProps['items'] = [
        {
            title: header.lxHeaderItemLabelFeatures,
            featureHeaderModalMenu: [
                {
                    title: header.lxHeaderItemLabelFeaturesOpeningMoves,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesOpeningMoves,
                    getUrl() {
                        return `/${lang}/features/opening-moves/`;
                    },
                    isActive: true,
                },
                {
                    title: header.lxHeaderItemLabelFeaturesVideoChat,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesVideoChat,
                    getUrl() {
                        return `/${lang}/features/video-chat/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesTravelMode,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesTravelMode,
                    getUrl() {
                        return `/${lang}/features/travel-mode/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesIncognitoMode,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesIncognitoMode,
                    getUrl() {
                        return `/${lang}/features/incognito-mode/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesSnoozeMode,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesSnoozeMode,
                    getUrl() {
                        return `/${lang}/features/snooze-mode/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesCompliments,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesCompliments,
                    getUrl() {
                        return `/${lang}/features/compliments/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesSuperswipe,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesSuperswipe,
                    getUrl() {
                        return `/${lang}/features/superswipe/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesInstantMatch,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesInstantMatch,
                    getUrl() {
                        return `/${lang}/features/instant-match/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesDiscover,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesDiscover,
                    getUrl() {
                        return `/${lang}/features/discover/`;
                    },
                },
                {
                    title: header.lxHeaderItemLabelFeaturesWebApp,
                    descriptionText: header.lxHeaderItemDescriptionFeaturesWebApp,
                    getUrl() {
                        return `/${lang}/features/web/`;
                    },
                },
            ],
        },
        {
            title: header.lxHeaderItemLabelShop,
            url: 'https://bumble.shop/',
            onClick: () => onMenuItemClickHandler('shop'),
        },
        {
            title: header.lxHeaderItemLabelApp,
            appHeaderModalMenu: [
                {
                    title: header.lxHeaderItemLabelAppModeDate,
                    descriptionText: header.lxHeaderItemDescriptionAppModeDate,
                    getUrl() {
                        return `/${lang}/date`;
                    },
                    onClick: () => onMenuItemClickHandler('date'),
                },
                {
                    title: header.lxHeaderItemLabelAppModeBFF,
                    descriptionText: header.lxHeaderItemDescriptionAppModeBFF,
                    getUrl() {
                        return `/${lang}/bff`;
                    },
                    onClick: () => onMenuItemClickHandler('bff'),
                },
                {
                    title: header.lxHeaderItemLabelAppModeBizz,
                    descriptionText: header.lxHeaderItemDescriptionAppModeBizz,
                    getUrl() {
                        return `/${lang}/bizz`;
                    },
                    onClick: () => onMenuItemClickHandler('bizz'),
                },
            ],
        },
        {
            title: header.lxHeaderItemLabelAmbassadors,
            url: '/bumble-honey-ambassadors',
            onClick: () => onMenuItemClickHandler('ambassadors'),
        },
        {
            title: header.lxHeaderItemLabelEvents,
            url: 'https://bumble.events/',
            onClick: () => onMenuItemClickHandler('events'),
        },
        {
            title: header.lxHeaderItemLabelBuzz,
            url: '/the-buzz/',
            onClick: () => onMenuItemClickHandler('blog'),
        },
        {
            title: header.lxHeaderItemLabelAbout,
            url: `/${lang}/about`,
            onClick: () => onMenuItemClickHandler('about'),
        },
    ];

    headerMenuItems.push(signinCta);

    React.useEffect(() => {
        setActivePages(headerMenuItems, window.location.href);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SkipLink />
            <Header
                logo={<Logo a11yLabel={common.lxA11yLogo} />}
                headerMenu={<HeaderMenu items={headerMenuItems} />}
                headerMobileMenu={
                    <HeaderMobileMenu
                        items={headerMenuItems}
                        id="mobile-menu"
                        ariaLabel={header.lxA11yNavigationMain}
                        onGoogleStoreClick={onGoogleStoreClick}
                        onAppleStoreClick={onAppleStoreClick}
                    />
                }
                hasMobileMenuToggle={true}
                onClickOpenMenu={onClickHeaderOpenMenuCta}
            />
        </>
    );
};
