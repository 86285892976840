import React, { useContext } from 'react';

import cx from 'classnames';
import LocaleContext from 'lib/locale-context';

import Link from 'components/link/link';

type HeaderMobileMenuSlideoverListProps = {
    linkList: {
        getUrl(lang: string): string;
        onClick?: () => void;
        title?: string | React.ReactNode;
        descriptionText?: string | React.ReactNode;
        isActive?: boolean;
    }[];
};

function HeaderMobileMenuSlideoverList(props: HeaderMobileMenuSlideoverListProps) {
    const lang = useContext(LocaleContext);
    return (
        <>
            {props.linkList.map((menuItem, dropdownIndex) => {
                const classNameSubLink = cx({
                    'modal-list__dropdown-link': true,
                    'is-active': menuItem.isActive,
                });

                return (
                    <div
                        className="modal-list__item"
                        key={`header-menu--dropdown-item-${dropdownIndex}`}
                    >
                        <Link
                            className={classNameSubLink}
                            href={menuItem.getUrl(lang)}
                            onClick={menuItem.onClick}
                        >
                            {menuItem.title}
                        </Link>
                        {menuItem.descriptionText && (
                            <p className="modal-list__description">{menuItem.descriptionText}</p>
                        )}
                    </div>
                );
            })}
        </>
    );
}

export default HeaderMobileMenuSlideoverList;
