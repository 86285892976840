import * as React from 'react';

import { common } from 'components/lexemes';
import { ExternalLink } from 'lib/app/external-link';
import Image from 'lib/image-container';
import LocaleContext from 'lib/locale-context';

export type AppLinksProps = {
    appStoreLink?: string;
    googleStoreLink?: string;
    onAppleStoreClick?: () => void;
    onGoogleStoreClick?: () => void;
    description: string;
    textCenter?: boolean;
    isCta?: boolean;
};

const AppLinks: React.FC<AppLinksProps> = ({
    appStoreLink,
    googleStoreLink,
    onAppleStoreClick,
    onGoogleStoreClick,
    description,
    isCta = false,
}) => {
    const lang = React.useContext(LocaleContext);

    return (
        <div className="app-links" id="app-links">
            <div className="app-links__list">
                <a
                    className={`app-links__link app-links__link--app-store app-links__link--app-store-${lang}
                    ${isCta ? 'app-links__link-background-cta' : 'app-links__link-background'}`}
                    href={appStoreLink || ExternalLink.APP_STORE_IOS}
                    onClick={onAppleStoreClick}
                >
                    <Image
                        src={`/bumble-brand-assets/bumble/l-assets/${lang}/appstore-badge.svg`}
                        unoptimized={true}
                        width={120}
                        height={40}
                        alt={common.lxA11yAppStore}
                    />
                </a>

                <a
                    className={`app-links__link app-links__link--google-play app-links__link--google-play-${lang}
                    ${isCta ? 'app-links__link-background-cta' : 'app-links__link-background'}`}
                    href={googleStoreLink || ExternalLink.APP_STORE_ANDROID}
                    onClick={onGoogleStoreClick}
                >
                    <Image
                        src={`/bumble-brand-assets/bumble/l-assets/${lang}/google-play-badge.png`}
                        width={151}
                        height={40}
                        alt={common.lxA11yGooglePlay}
                    />
                </a>
            </div>

            <p
                className={`${isCta ? `app-links__align_center` : ''} app-links__description`}
                dangerouslySetInnerHTML={{
                    __html: description,
                }}
            />
        </div>
    );
};

export default AppLinks;
