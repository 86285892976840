import React, { useContext, useState } from 'react';

import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import LocaleContext from 'lib/locale-context';

import A11yVisuallyHidden from 'components/a11y/a11y-visually-hidden';
import Link from 'components/link/link';
import NavigationModal from 'components/navigation-modal/navigation-modal';
import NavigationModalList from 'components/navigation-modal/navigation-modal-list';

export type HeaderMenuProps = {
    items?: {
        isActive?: boolean;
        appHeaderModalMenu?: {
            getUrl(lang: string): string;
            onClick?: () => void;
            title?: string | React.ReactNode;
            descriptionText?: string | React.ReactNode;
            isActive?: boolean;
        }[];
        featureHeaderModalMenu?: {
            getUrl(lang: string): string;
            onClick?: () => void;
            title?: string | React.ReactNode;
            descriptionText?: string | React.ReactNode;
            isActive?: boolean;
        }[];
        url?: string;
        onClick?: () => void;
        title?: string | React.ReactNode;
    }[];
};

type ModalId = 'appModal' | 'featureModal' | null;

function HeaderMenu({ items }: HeaderMenuProps) {
    const lang = useContext(LocaleContext);
    const [showMenu, setMenuVisible] = useState(false);

    const [activeModal, setActiveModal] = useState<ModalId>(null);
    const openModal = (modalId: ModalId) => setActiveModal(modalId);
    const closeModal = () => setActiveModal(null);

    return (
        <ul className="header-menu">
            {items &&
                items.map((item, index) => {
                    const {
                        isActive,
                        appHeaderModalMenu,
                        featureHeaderModalMenu,
                        url,
                        title,
                        onClick,
                    } = item;

                    const classNameLink = cx({
                        'header-menu__link': true,
                        'is-active': isActive,
                        'has-dropdown':
                            (appHeaderModalMenu && !url) || (featureHeaderModalMenu && !url),
                    });

                    return (
                        <li className="header-menu__item" key={`header-menu-item-${index}`}>
                            {url ? (
                                <Link
                                    className={classNameLink}
                                    href={url}
                                    onClick={onClick}
                                    aria-current={isActive ? 'page' : undefined}
                                >
                                    {title}
                                </Link>
                            ) : null}

                            {appHeaderModalMenu ? (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: true,
                                        onDeactivate: () => setMenuVisible(false),
                                        initialFocus: false,
                                        allowOutsideClick: true,
                                    }}
                                    active={showMenu}
                                >
                                    <div className="header-menu__dropdown-link-container">
                                        {/*
                                            if menu link is proper anchor, we show addiitonal button to expand dropdown
                                            however if menu link is just a dropdown opener, we don't need an additional button
                                        */}
                                        <button
                                            className={
                                                url ? 'header-menu__dropdown-toggle' : classNameLink
                                            }
                                            aria-expanded={Boolean(showMenu)}
                                            onClick={() => openModal('appModal')}
                                            type="button"
                                        >
                                            {url ? (
                                                <A11yVisuallyHidden>
                                                    {showMenu ? 'Hide submenu' : 'Show submenu'}
                                                </A11yVisuallyHidden>
                                            ) : (
                                                title
                                            )}
                                        </button>
                                        <NavigationModal
                                            isOpen={activeModal === 'appModal'}
                                            onClose={closeModal}
                                        >
                                            <NavigationModalList linkList={appHeaderModalMenu} />
                                        </NavigationModal>

                                        {showMenu ? (
                                            <ul
                                                className="header-menu__dropdown"
                                                data-test-id="header-dropdown"
                                            >
                                                {appHeaderModalMenu &&
                                                    appHeaderModalMenu.map(
                                                        (menuItem, dropdownIndex) => {
                                                            const classNameSubLink = cx({
                                                                'header-menu__dropdown-link': true,
                                                                'is-active': menuItem.isActive,
                                                            });

                                                            return (
                                                                <li
                                                                    className="header-menu__dropdown-item"
                                                                    key={`header-menu--dropdown-item-${dropdownIndex}`}
                                                                >
                                                                    <Link
                                                                        className={classNameSubLink}
                                                                        href={menuItem.getUrl(lang)}
                                                                        onClick={menuItem.onClick}
                                                                    >
                                                                        {menuItem.title}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        },
                                                    )}
                                            </ul>
                                        ) : null}
                                    </div>
                                </FocusTrap>
                            ) : null}

                            {featureHeaderModalMenu ? (
                                <FocusTrap
                                    focusTrapOptions={{
                                        escapeDeactivates: true,
                                        onDeactivate: () => setMenuVisible(false),
                                        initialFocus: false,
                                        allowOutsideClick: true,
                                    }}
                                    active={showMenu}
                                >
                                    <div className="header-menu__dropdown-link-container">
                                        {/*
                                            if menu link is proper anchor, we show addiitonal button to expand dropdown
                                            however if menu link is just a dropdown opener, we don't need an additional button
                                        */}
                                        <button
                                            className={
                                                url ? 'header-menu__dropdown-toggle' : classNameLink
                                            }
                                            aria-expanded={Boolean(showMenu)}
                                            type="button"
                                            onClick={() => openModal('featureModal')}
                                        >
                                            {url ? (
                                                <A11yVisuallyHidden>
                                                    {showMenu ? 'Hide submenu' : 'Show submenu'}
                                                </A11yVisuallyHidden>
                                            ) : (
                                                title
                                            )}
                                        </button>
                                        <NavigationModal
                                            isOpen={activeModal === 'featureModal'}
                                            onClose={closeModal}
                                        >
                                            <NavigationModalList
                                                linkList={featureHeaderModalMenu}
                                            />
                                        </NavigationModal>
                                    </div>
                                </FocusTrap>
                            ) : null}
                        </li>
                    );
                })}
        </ul>
    );
}

export default HeaderMenu;
