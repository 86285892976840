import React, { ReactNode, FunctionComponent, ElementType } from 'react';

import classnames from 'classnames';
import { SupportedLanguage } from 'lib/lexemes/supported-languages';

type Props = {
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';
    color?: 'default' | 'inverse' | 'brand';
    children?: ReactNode | string;
    lang?: SupportedLanguage;
};

const CtaBoxHeader: FunctionComponent<Props> = ({
    tag = 'div',
    children,
    color = 'default',
    lang,
}) => {
    const Tag = tag as ElementType;

    const className = classnames({
        'cta-box__header': true,
        [`color-${color}`]: color,
    });

    return (
        <Tag className={className} lang={lang}>
            {children}
        </Tag>
    );
};

export default CtaBoxHeader;
